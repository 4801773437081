<template>
  <Sidebar
    :dismissable="false"
    :modal="false"
    position="right"
    v-bind="$attrs"
    v-model:visible="visibleProxy"
    :class="rootClasses"
    :baseZIndex="baseZIndex"
    :showCloseIcon="false"
    @keydown.stop=""
    :pt="{
      header: { class: 'hidden' },
    }"
  >
    <div class="flex overflow-hidden" :style="getSidebarStyle">
      <div class="flex flex-column" :style="getLeftColumnStyle">
        <!-- Left content -->
        <div v-if="headerTitle" ref="sidebarHeaderRef" id="sidebar-header">
          <h2 class="font-semibold p-3 text-overflow-ellipsis overflow-x-hidden white-space-nowrap">
            {{ headerTitle }}
          </h2>
        </div>

        <div v-if="loading" class="h-full py-4 px-3" id="sidebar-loading">
          <div class="grid">
            <template v-if="large">
              <div v-for="num in 6" :key="num" class="col-6">
                <Skeleton />
              </div>
            </template>
            <template v-else>
              <div v-for="num in 4" :key="num" class="col-12">
                <Skeleton />
              </div>
            </template>
          </div>
        </div>

        <Form
          v-else
          :form-id="formId"
          :schema="schema"
          :initialValues="initialValues"
          ref="formComponent"
          id="sidebar-form"
          @onValidateSuccess="emit('primaryAction', $event)"
          @onValidateFail="handleValidationFail"
        >
          <template v-slot="{ isFormValid, form }">
            <SidebarFormTabView
              v-if="tabItems?.length"
              v-show="!showContentSlot"
              :tabItems="tabItems"
              v-model:tabErrors="tabErrors"
              :contentHeight="getContentHeight"
              :contentPadding="contentPadding"
            />
            <div
              v-if="$slots?.content"
              v-show="showContentSlot"
              class="overflow-auto pt-3 px-3"
              :style="getContentHeight"
              ref="sidebarScrollRef"
              id="sidebar-content"
            >
              <slot name="content" />
            </div>
          </template>
        </Form>

        <div ref="sidebarFooterRef" v-if="$slots?.customFooter || footerNote" id="sidebar-footer">
          <div
            class="bottom-0 sticky z-1 bg-white c-sidebar-form-footer pt-3"
            :class="contentPadding"
          >
            <div class="grid">
              <div class="col-12" v-if="$slots?.customFooter" id="sidebar-footer-custom">
                <slot name="customFooter" />
              </div>

              <div class="col-12" v-if="footerNote" id="sidebar-footer-note">
                <div class="notes">
                  {{ footerNote }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref="sidebarActionBarRef" class="h-full" id="sidebar-action-bar">
        <SidebarFormActionBar
          :loading="loading"
          :saving="saving"
          :primaryActionLabel="primaryActionLabel"
          :primaryActionIcon="primaryActionIcon"
          :disablePrimaryAction="disablePrimaryAction"
          :menuActions="menuActions"
          :menuActionLabel="menuActionLabel"
          :actionBarActions="actionBarActions"
          @confirm="confirm"
          @cancel="cancel"
        />
      </div>
    </div>
  </Sidebar>

  <SidebarFormErrorDialog
    v-model="showValidationError"
    :tabErrors="tabErrors"
    id="sidebar-error-dialog"
  />
</template>

<script lang="ts">
export default { name: 'SidebarForm', inheritAttrs: false };
</script>

<script setup lang="ts">
import { onMounted, type PropType, ref, onUnmounted, computed, type Ref, watch } from 'vue';
import { TagComponentSeverities } from '../../../core/misc';
import { useConfirmDialog } from '../../../core/overlays/confirm-dialog';
import { Sidebar } from '../../../core/overlays/sidebar';
import { Skeleton } from './../../../core/misc';
import { Form } from '../form';
import SidebarFormActionBar from './components/sidebar-form-action-bar/SidebarFormActionBar.vue';
import SidebarFormErrorDialog from './components/sidebar-form-error-dialog/SidebarFormErrorDialog.vue';
import SidebarFormTabView from './components/sidebar-form-tab-view/SidebarFormTabView.vue';
import type {
  Schema,
  SidebarFormActionBarAction,
  SidebarFormMenuAction,
  SidebarFormTabComponentItem,
  SidebarFormTabErrors,
} from '@centric-os/types';
import { useSidebarForm } from './composables';
import { useFormSidebarStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';
import { useTranslation } from '@centric-os/helpers';

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  headerTitle: {
    type: String,
  },
  footerNote: {
    type: String,
  },
  primaryActionLabel: {
    type: String,
    required: true,
  },
  primaryActionIcon: {
    type: String,
  },
  menuActionLabel: {
    type: String,
    default: '',
  },
  schema: {
    type: Object as PropType<Schema>,
    required: true,
    default: () => ({}) as Schema,
  },
  initialValues: {
    type: Object,
  },
  saving: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disablePrimaryAction: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: true,
  },
  baseZIndex: {
    type: Number,
    default: 1,
  },
  actionBarActions: {
    type: Array as PropType<SidebarFormActionBarAction[]>,
    default: () => [],
  },
  menuActions: {
    type: Array as PropType<SidebarFormMenuAction[]>,
    default: () => [],
  },
  tabItems: {
    type: Array as PropType<SidebarFormTabComponentItem[]>,
    default: () => [],
  },
});

const {
  rootClasses,
  getSidebarStyle,
  contentPadding,
  sidebarHeaderRef,
  sidebarFooterRef,
  sidebarActionBarRef,
  getContentHeight,
  getLeftColumnStyle,
  sidebarScrollRef,
  showContentSlot,
  setSidebarSizeByBreakpoints,
  displayContentSlot,
  displayTabs,
} = useSidebarForm(props);

const emit = defineEmits(['primaryAction']);

const visibleProxy = computed({
  get() {
    return visibleValue.value;
  },
  set() {
    cancel();
  },
});

const visibleValue = defineModel({
  type: Boolean,
  required: true,
});

const formComponent = ref<InstanceType<typeof Form>>();

const { translate } = useTranslation();

const showValidationError = ref(false);
const tabErrors: Ref<SidebarFormTabErrors> = ref({});

const { showConfirmDialog, close } = useConfirmDialog({
  header: translate(`leaveDialog.header`, 'You have unsaved changes'),
  message: translate(`leaveDialog.message`, 'If you close this sidebar, all changes will be lost.'),
  acceptLabel: translate(`leaveDialog.acceptLabel`, 'Close'),
  rejectLabel: translate(`leaveDialog.rejectLabel`, 'Cancel'),
  severity: TagComponentSeverities.INFO,
});

const handleValidationFail = (): void => {
  if (!showContentSlot.value) {
    showValidationError.value = true;
  }
};
const resetScroll = (): void => {
  if (showContentSlot.value) {
    sidebarScrollRef.value.scrollTo(0, 0);
  }
};

const cancel = (): void => {
  if (formComponent?.value?.isDirty) {
    showConfirmDialog(
      () => closeSidebar(),
      () => ({}),
    );
  } else {
    closeSidebar();
  }
};

const closeSidebar = (): void => {
  formComponent?.value?.form?.resetForm();
  tabErrors.value = {};
  visibleValue.value = false;
  isFormDirty.value = false;
};

const confirm = async (): Promise<void> => {
  formComponent?.value?.validate();
};

onMounted(() => {
  setSidebarSizeByBreakpoints();
});

onUnmounted(() => {
  close();
  isFormDirty.value = false;
});

const formSidebarStore = useFormSidebarStore();
const { isFormDirty } = storeToRefs(formSidebarStore);
watch(
  () => formComponent.value?.isDirty,
  (newVal) => (isFormDirty.value = newVal),
);

defineExpose({
  formComponent,
  resetScroll,
  displayContentSlot,
  displayTabs,
});
</script>
